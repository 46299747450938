/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import styles section **/
import './CertificatesStyles.scss';

/** Import helpers section **/
import useWindowSize from '../../helpers/useWindowSize';
import { T } from 'react-translator-component';

/** Import component section **/
import CountriesMenu from './CountriesMenu';
import CountryModal from './CountryModal';
import CertificatesGlobe from '../../components/CertificatesGlobe';

function CertificatesView({
  data,
  selectedCountry,
  inRotation,
  onPointClick,
  onGlobeClick,
  onMenuClick,
  onCountrySelect
}) {
  const [width, height] = useWindowSize();
  return (
    <div className="certificates">
      {data?.length > 0 && (
        <div className="certificates_countries-select">
          <select
            // value={selectedCountry ? selectedCountry : ''}
            onChange={onCountrySelect}
          >
            <option value="">{T('Selecciona el país')}</option>
            {data.map((country, index) => (
              <option key={index} value={country.id}>
                {country['name_' + T('es')]}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="certificates_globe" onClick={onGlobeClick}>
        <CertificatesGlobe
          width={width}
          height={height - 20}
          globeData={data}
          selectedCountry={selectedCountry}
          onPointClick={onPointClick}
        />
      </div>
      {data?.length > 0 && (
        <div className="certificates_countries-menu">
          <CountriesMenu
            countries={data}
            selectedCountry={selectedCountry}
            onClick={onMenuClick}
          />
        </div>
      )}
      {!inRotation && selectedCountry && (
        <div className="certificates_country-modal">
          <CountryModal data={selectedCountry} />
        </div>
      )}
    </div>
  );
}

export default CertificatesView;
