/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import arrowIcon from '../../../assets/images/icon-arrow.png';

/** Import helpers section **/
import { T } from 'react-translator-component';

/** Import component section **/

import './CountriesMenuStyles.scss';

function CountriesMenuView({ data, selectedCountry, onClick }) {
  return (
    <div className="countriesMenu">
      <div className="countriesMenu_title">
        {T('POR PAISES')}{' '}
        <img
          src={arrowIcon}
          alt="arrow"
          className="countriesMenu_title_arrow"
        />
      </div>
      <div className="countriesMenu_items g-scroll">
        {data.map((country, index) => (
          <div
            key={index}
            className={
              'countriesMenu_items_country' +
              (selectedCountry?.id === country?.id ? ' selected' : '')
            }
            onClick={(e) => onClick(country)}
          >
            {country['name_' + T('es')]}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CountriesMenuView;
