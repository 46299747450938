/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import styles section **/
import './MenuStyles.scss';

/** Import helpers section **/

/** Import component section **/
import MenuItem from './MenuItem';

export const MenuView = ({ items }) => {
  return (
    <div className="mainmenu">
      {items?.map((item, index) => (
        <div className="mainmenu_item" key={index}>
          <MenuItem item={item} />
        </div>
      ))}
    </div>
  );
};

export default MenuView;
