import './styles/app.scss';
import Main from './layout/Main';

import { Translator, Config } from 'react-translator-component';
import { TRANSLATIONS } from './config/APP_CONFIGS';

Config.default = 'es';
Config.list = TRANSLATIONS;

function App() {
  Config.default = 'es';
  return (
    <Translator>
      <Main />
    </Translator>
  );
}

export default App;
