/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import prizeIcon from '../../../assets/images/prize.png';
import personIcon from '../../../assets/images/icon_person.png';

/** Import helpers section **/
import { T } from 'react-translator-component';

/** Import component section **/

import './CountryModalStyles.scss';

function CountryModalView({ data, onCertificateSelect }) {
  return (
    <div className="countryModal">
      <div className="countryModal_country">
        <div className="countryModal_country-prize">
          <img src={prizeIcon} alt="prize" />
        </div>
        {data['name_' + T('es')]}
      </div>

      <div className="countryModal_items g-scroll">
        {data?.certificates?.map((item, index) => (
          <div
            key={index}
            lang="en"
            className="countryModal_items_item"
            onClick={(e) => onCertificateSelect(item)}
          >
            <div className="countryModal_items_item-person">
              <img src={personIcon} alt="person" />
            </div>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CountryModalView;
