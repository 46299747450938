/** Import react/libraries section **/
import { useLayoutEffect, useState } from 'react';

function useRTCLanguage() {
  const [language, setLanguage] = useState(null);
  useLayoutEffect(() => {
    function updateLanguage() {
      setLanguage(localStorage.getItem('rtc-lang'));
    }
    updateLanguage();
  }, []);
  return language;
}

export default useRTCLanguage;
