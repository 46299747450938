module.exports = {
  es: 'en',
  'Quiénes somos': 'About Us',
  'Buscador mundial de detalladores certificados':
    'Global Certified Detailers Dearch',
  Contacto: 'Contact',
  'Acredita tus conocimientos con la única':
    'Accredit your knowledge with the only',
  'certificación con respaldo internacional':
    'certification program with international support.',
  'Bienvenido a la red más grande de profesionales del detallado automotriz en el mundo.':
    'Welcome to the largest network of automotive professional detailing in the world.',
  'FIDTAIL ha impartido cientos de cursos acreditados sobre detallado automotriz PREMIUM, con miles de alumnos graduados en más de 16 países.':
    'FIDTAIL has taught hundreds of accredited courses in automotive detailing PREMIUM, with thousands of graduate students in more than 16 countries.',
  'Los centros de detallado automotriz internacionales están creciendo con rapidez y FIDTAIL está especialmente calificado para ayudarlos a avanzar profesionalmente y mejorar la técnica de calidad de cada trabajo.':
    'International automotive detailing centers are growing fast and FIDTAIL is uniquely qualified to help them advance professionally and improve the quality technique of each job.',
  'Niveles de Certificación': 'Certification levels',
  'POR PAISES': 'BY COUNTRIES',
  'Selecciona el país': 'Select the country',
  'Utilice el formulario de contacto si tiene alguna pregunta o solicitud sobre nuestros servicios':
    'Use the contact form if you have any questions about our services',
  Nombre: 'Name',
  Correo: 'Email',
  Mensaje: 'Message',
  Enviar: 'Send'
};
