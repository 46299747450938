/** Import react/libraries section **/
import React, { useState, useEffect } from 'react';

/** Import helpers section **/
import { LanguageList, Config } from 'react-translator-component';
import useRTCLanguaje from '../../helpers/useRTCLanguaje';

/** Import styles section **/
import './LanguageSelectorStyles.scss';

/** Import resources section **/

/** Import component section **/

function LanguageSelectorComponent() {
  const [language, setLanguage] = useState(Config.default);
  const [showList, setShowList] = useState(false);

  let storedLanguage = useRTCLanguaje();

  useEffect(() => {
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, [storedLanguage]);

  const handleOnClickOutside = (e) => {
    const classList = e.target.classList;
    if (
      !classList.contains('languageSelector_dropdown') &&
      !classList.contains('languageSelector_dropdown_item_icon') &&
      !classList.contains('languageSelector_selected') &&
      !classList.contains('languageSelector_selected_image')
    ) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleOnClickOutside);
    };
  }, []);

  const handleOnChangeLanguage = (key) => {
    setLanguage(key);
    setShowList(false);
  };

  const handleShowList = (event) => {
    setShowList(!showList);
  };

  const Dropdown = () => {
    return (
      <div className="languageSelector_dropdown">
        {Object.keys(Config.list).map((key) => (
          <div key={key}>
            {key !== language && (
              <div
                value={key}
                className="languageSelector_dropdown_item"
                onClick={(e) => handleOnChangeLanguage(key)}
              >
                <img
                  src={Config.list[key].icon}
                  alt="language"
                  className="languageSelector_dropdown_item_icon"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="languageSelector">
      <LanguageList Language={language} />
      {language && (
        <div className="languageSelector_selected" onClick={handleShowList}>
          <img
            src={Config.list[language].icon}
            alt="language-select"
            className="languageSelector_selected_image"
          />
        </div>
      )}
      {showList && <Dropdown />}
    </div>
  );
}
export default LanguageSelectorComponent;
