import React from 'react';

/** Import services section **/

/** Import component section **/
import CountriesMenuView from './CountriesMenuView';

function CountriesMenuComponent({ countries, selectedCountry, onClick }) {
  return (
    <CountriesMenuView
      data={countries}
      selectedCountry={selectedCountry}
      onClick={onClick}
    />
  );
}

export default CountriesMenuComponent;
