import React from 'react';

/** Import services section **/

/** Import component section **/
import CertificateModalView from './CertificateModalView';

function CertificateModalComponent({ data, onClose }) {
  return <CertificateModalView data={data} onClose={onClose} />;
}

export default CertificateModalComponent;
