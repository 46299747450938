/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import helpers section **/
import MENU_ITEMS from './MENU_ITEMS';

/** Import component section **/
import MenuView from './MenuView';

export const MenuComponent = () => {
  return <MenuView items={MENU_ITEMS} />;
};

export default MenuComponent;
