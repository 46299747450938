import esFlag from '../assets/flags/es.svg';
import enFlag from '../assets/flags/en.svg';
// import frFlag from '../assets/flags/fr.svg';

/*********************************************************************
 **************************  APP CONFIG  *****************************
 *********************************************************************/
export const LOCAL_STORAGE_PREFIX = 'fidtail';
export const APP_NAME = 'Fidtail 2021';
export const TRANSLATIONS = {
  es: {
    text: 'Español',
    icon: esFlag,
    file: require('../translations/es')
  },
  en: {
    text: 'English',
    icon: enFlag,
    file: require('../translations/en')
  }
  // fr: {
  //   text: 'Français',
  //   icon: frFlag,
  //   file: require('../translations/fr')
  // }
};
