/** Import react/libraries section **/
import React, { useEffect, useRef } from 'react';
import Globe from 'react-globe.gl';
// import * as THREE from 'three';

/** Import resources section **/

/** Import styles section **/

/** Import helpers section **/
import { T } from 'react-translator-component';

/** Import component section **/

/**
 * Comments: component made using: https://github.com/vasturiano/react-globe.gl
 * commands
 * npm i three
 * npm i react-globe.gl
 *
 * **/
const ROTATION_SPEED = 1000;

const INITIAL_LOCATION = {
  lat: 23.6345,
  lng: -102.55278,
  altitude: 1.7
};

const CertificatesGlobeComponent = ({
  width,
  height,
  globeData,
  selectedCountry,
  onPointClick
}) => {
  const globeEl = useRef();

  useEffect(() => {
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = -2;
    globeEl.current.controls().rotateSpeed = 5;
    globeEl.current.pointOfView(INITIAL_LOCATION, 0);
  }, []);

  useEffect(() => {
    if (!selectedCountry) return;
    let location = {
      lat: selectedCountry?.latitude,
      lng: selectedCountry?.longitude
    };
    globeEl.current.pointOfView(location, ROTATION_SPEED);
    globeEl.current.controls().autoRotate = false;
  }, [selectedCountry]);

  const PointLabel = (point) => {
    const nameInLanguage = point['name_' + T('es')];
    return (
      '<div style="background:red; padding: 5px; font-size: 10px; border-radius:5px;" >' +
      nameInLanguage +
      '</div>'
    );
  };

  const handleOnPointClick = (obj) => {
    onPointClick(obj.id);
  };
  return (
    <Globe
      ref={globeEl}
      globeImageUrl="/line-earth-background.jpg"
      width={width}
      height={width > 800 ? height - 60 : width - 60}
      backgroundColor="#00000000"
      animateIn={false}
      showGraticules={true}
      showAtmosphere={true}
      atmosphereColor={'#fff'}
      atmosphereAltitude={0.2}
      pointsData={globeData}
      pointColor={() => 'red'}
      pointAltitude={0.05}
      pointRadius={2}
      pointLat={(obj) => obj.latitude}
      pointLng={(obj) => obj.longitude}
      pointLabel={(obj) => PointLabel(obj)}
      onPointClick={handleOnPointClick}
    />
  );
};

export default CertificatesGlobeComponent;
