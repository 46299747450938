import React, { useState } from 'react';

/** Import services section **/

/** Import component section **/
import CountryModalView from './CountryModalView';
import CertificateModal from '../CertificateModal';

function CountryModalComponent({ data }) {
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const handleOnCertificateSelect = (item) => {
    setSelectedCertificate(item);
  };

  const handleOnCertificateClose = () => {
    setSelectedCertificate(null);
  };

  return (
    <>
      <CountryModalView
        data={data}
        onCertificateSelect={handleOnCertificateSelect}
      />
      {selectedCertificate && (
        <CertificateModal
          data={selectedCertificate}
          onClose={handleOnCertificateClose}
        />
      )}
    </>
  );
}

export default CountryModalComponent;
