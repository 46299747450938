module.exports = {
  es: 'es',
  'Quiénes somos': 'Quiénes somos',
  'Buscador mundial de detalladores certificados':
    'Buscador mundial de detalladores certificados',
  Contacto: 'Contacto',
  'Acredita tus conocimientos con la única':
    'Acredita tus conocimientos con la única',
  'certificación con respaldo internacional':
    'certificación con respaldo internacional',
  'Bienvenido a la red más grande de profesionales del detallado automotriz en el mundo.':
    'Bienvenido a la red más grande de profesionales del detallado automotriz en el mundo.',
  'FIDTAIL ha impartido cientos de cursos acreditados sobre detallado automotriz PREMIUM, con miles de alumnos graduados en más de 16 países.':
    'FIDTAIL ha impartido cientos de cursos acreditados sobre detallado automotriz PREMIUM, con miles de alumnos graduados en más de 16 países.',
  'Los centros de detallado automotriz internacionales están creciendo con rapidez y FIDTAIL está especialmente calificado para ayudarlos a avanzar profesionalmente y mejorar la técnica de calidad de cada trabajo.':
    'Los centros de detallado automotriz internacionales están creciendo con rapidez y FIDTAIL está especialmente calificado para ayudarlos a avanzar profesionalmente y mejorar la técnica de calidad de cada trabajo.',
  'Niveles de Certificación': 'Niveles de Certificación',
  'POR PAISES': 'POR PAISES',
  'Selecciona el país': 'Selecciona el país',
  'Utilice el formulario de contacto si tiene alguna pregunta o solicitud sobre nuestros servicios':
    'Utilice el formulario de contacto si tiene alguna pregunta o solicitud sobre nuestros servicios',
  Nombre: 'Nombre',
  Correo: 'Correo',
  Mensaje: 'Mensaje',
  Enviar: 'Enviar'
};
