/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import prizeIcon from '../../../assets/images/prize.png';
import companyIcon from '../../../assets/images/icon_company.png';
import addressIcon from '../../../assets/images/icon_address.png';
import phoneIcon from '../../../assets/images/icon_phone.png';
import emailIcon from '../../../assets/images/icon_email.png';
import facebookIcon from '../../../assets/images/icon_facebook.png';
import instagramIcon from '../../../assets/images/icon_instagram.png';

/** Import helpers section **/

/** Import component section **/

import './CertificateModalStyles.scss';

const LOGOS_URL = process.env.REACT_APP_BACK_URL + 'logos/';

function CertificateModalView({ data, onClose }) {
  const handleOnClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div className="certificatesModal" onClick={onClose}>
      <div className="certificatesModal_container" onClick={handleOnClick}>
        <div className="certificatesModal_container_certificate">
          <img
            src={prizeIcon}
            alt="prize"
            className="certificatesModal_container_certificate-prize"
          />
          {data?.name}
        </div>
        {data.logo && (
          <div className="certificatesModal_container_logo">
            <img src={LOGOS_URL + data.logo} alt="logo" />
          </div>
        )}
        <div className="certificatesModal_container_info">
          {data.company && data.company?.length > 0 && (
            <div className="certificatesModal_container_info_data">
              <div className="certificatesModal_container_info_data-icon">
                <img src={companyIcon} alt="company" />
              </div>
              {data.company}
            </div>
          )}
          {data.address && data.address?.length > 0 && (
            <div className="certificatesModal_container_info_data">
              <div className="certificatesModal_container_info_data-icon">
                <img src={addressIcon} alt="address" />
              </div>
              {data.address}
            </div>
          )}
          {data.phone && data.phone?.toString().length > 0 && (
            <div className="certificatesModal_container_info_data">
              <div className="certificatesModal_container_info_data-icon">
                <img src={phoneIcon} alt="phone" />
              </div>
              {data.phone}
            </div>
          )}
          {data.email && data.email?.toString().length > 0 && (
            <div className="certificatesModal_container_info_data">
              <div className="certificatesModal_container_info_data-icon">
                <img src={emailIcon} alt="email" />
              </div>
              {data.email}
            </div>
          )}
          {data.facebook && data.facebook?.toString().length > 0 && (
            <div className="certificatesModal_container_info_data">
              <div className="certificatesModal_container_info_data-icon">
                <img src={facebookIcon} alt="facebook" />
              </div>
              {data.facebook}
            </div>
          )}
          {data.instagram && data.instagram?.toString().length > 0 && (
            <div className="certificatesModal_container_info_data">
              <div className="certificatesModal_container_info_data-icon">
                <img src={instagramIcon} alt="instagram" />
              </div>
              {data.instagram}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CertificateModalView;
