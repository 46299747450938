/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import logo from '../../assets/images/logo.png';
import menuIcon from '../../assets/images/icon-menu.png';

/** Import styles section **/
import './HeaderStyles.scss';

/** Import helpers section **/

/** Import component section **/
import Menu from '../Menu';
import LenguageSelector from '../../components/LanguageSelector';

export const HeaderView = ({ menuOpen, onMenuClick }) => {
  return (
    <div className="mainheader">
      <div className="mainheader_logo">
        <img src={logo} alt="fidtail-logo" />
      </div>
      <div className="mainheader_actions">
        <div className="mainheader_actions_menu">
          <Menu />
        </div>
        <LenguageSelector />
        <div className="mainheader_actions-menuicon" onClick={onMenuClick}>
          <img src={menuIcon} alt="menu-icon" />
        </div>
        {menuOpen && (
          <div className="mobile-menu" onClick={onMenuClick}>
            <Menu />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderView;
