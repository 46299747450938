/** Import react/libraries section **/
import React, { useState } from 'react';

/** Import resources section **/

/** Import helpers section **/

/** Import component section **/
import HeaderView from './HeaderView';

export const HeaderComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOnMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  return <HeaderView menuOpen={menuOpen} onMenuClick={handleOnMenuClick} />;
};

export default HeaderComponent;
